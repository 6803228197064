import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import { useRef } from "react"
import Layout from "../components/core/Layout"
import Navigation from "../components/core/Navigation"

import beyondSymbol from "../images/common/symbol-beyond.svg"
import locationIcon from "../images/common/location-icon.svg"
import phoneIcon from "../images/common/phone-icon.svg"
import quoteIcon from "../images/common/quote-icon.svg"
import { Link } from "gatsby"

const IndexPage = () => {

    const contactRef = useRef(null);

    function contactScrollTo(){
    
        if (contactRef && contactRef.current) {
          contactRef.current.scrollIntoView({ behavior: 'smooth'});
          setTimeout(function(){
            document.location.hash = "#contact";
          }, 500); 
        }
      }


  return (
    <Layout>
       <section id="hero-section"> 
            <div className="container"><Navigation /></div>
            <div className="container">
                    <div className="columns hero-cols">
                      <div className="column is-6-tablet is-5-desktop hero-desc-group">
                          <div className="tile is-ancestor is-vertical">
                              <div className="tile hero-title">
                                <p>Integrative, <i>compassionate</i> and patient oriented care for women in the South Bay</p>
                              </div>
                              <div className="tile name">
                              Dr. Antoaneta Mueller, M.D.
                              </div>
                              <div className="tile cert-title">
                              FACOG
                              </div>
                              <div className="tile cert-desc">
                              Premier Board Certified Ob-Gyn, having served in the<br/> Long Beach community since 2007.
                              </div>
                              <div className="tile book-btn">
                                <div className="btn-container">
                                  <div className="btn-content" onClick={contactScrollTo}>BOOK APPOINTMENT</div>
                                </div>
                              </div>
                          </div>
                      </div>
                      <div className="column is-6-tablet is-6-desktop is-offset-1-desktop hero-img-group">
                      <div className="tile hero-title">
                                <p>Integrative, <i>compassionate</i> and patient oriented care for women in the South Bay</p>
                              </div>
                          <StaticImage alt="Dr. Antoaneta Mueller, M.D., FACOG, Premier Board Certified Ob-Gyn - South Bay CA" loading="eager" quality="100" className="hero-img"  src="../images/home/hero-image@2x.png"></StaticImage>
                      </div>
                    </div>
              </div>
              <div className="hero-bg"></div>
       </section>

       <section id="quote-intro">
            <div className="container">
                    <div className="columns quote-cols is-centered">
                      <div className="column is-8-tablet is-6-desktop quote-group">
                          <div className="tile is-ancestor is-vertical">
                                <div className="tile">
                                    <img alt="Quote Icon" className="quote-icn" src={quoteIcon} />
                                </div>
                                <div className="tile quote-prim">
                                    <p>“ I am passionate about</p><p> women’s health care and </p><p> that is why <div className="quote-highlight-cont"><span className="quote-highlight">I love my job.</span><span className="highlight-border"></span></div>”</p>
                                </div>
                                <div className="tile">
                                    <p className="quote-secnd">As a board certified Ob-Gyn I have dedicated my career to promoting an integrative approach no matter what stage of life you are in. My approach is compassionate and patient oriented.</p>
                                </div>
                                <div className="tile">
                                    <p className="quote-name">Dr. Antoaneta Mueller, M.D.</p>
                                </div>
                          </div>
                      </div>
                    </div>
            </div>
       </section>



       <section id="services">
            <div className="container">
                  <p className="service-title">Services We Offer</p>
                  <p className="service-desc">Women today have a lot on their plate between work and family responsibilities. I recognize the importance of addressing their health needs urgently so that they could return to their daily life.</p>

                  <div className="columns service-list">
                          <div className="column is-4 service-opt">
                              <StaticImage alt="Happy Woman At Gynecology Exam" quality="100" className="service-img" imgStyle={{ objectFit: 'contain' }} src="../images/home/service-image-gynecology@2x.png" />
                              <div className="service-label">GYNECOLOGY</div>
                          </div>
                          <div className="column is-4 service-opt">
                              <StaticImage alt="Gynecologist With Female Patient In The Office" quality="100" className="service-img" imgStyle={{ objectFit: 'contain' }} src="../images/home/service-image-gyn-surgery@2x.png" />
                              <div className="service-label gyn">GYN SURGERY</div>
                          </div>
                          <div className="column is-4 service-opt service-menopause">
                             <a href="https://beyondmymenopause.com/">
                              <StaticImage alt="A doctor providing care for her patient" quality="100" className="service-img" imgStyle={{ objectFit: 'contain' }} src="../images/home/service-image-menopause@2x.png" />
                              <div className="service-label">MENOPAUSE</div>
                              <div className="service-new">New</div>
                             </a>
                          </div>
                  </div>

                  <Link to="/services" className="services-btn">
                      <div className="btn-container">
                        <div className="btn-content">VIEW ALL SERVICES</div>
                      </div>
                  </Link>
            </div>
       </section>


       <section id="potenza">
            <div className="container">
                <div className="columns is-multiline">
                    <div className="column is-12 potenza-main-title">Aesthetics</div>

                    <div className="column pot-img is-centered">
                        <StaticImage alt="Microneedling is a versatile treatment" className="potenza-img" quality="100" src="../images/home/beyond-aesthetics-image@2x.png" />
                    </div>   
                    <div className="column pot-desc">
                        <div className="column is-6 potenza-title">POTENZA RF MICRONEEDLING</div>
                        <div className="column is-6 potenza-title-secondary">LAUNCHING 2025</div>

                        <div className="column is-6 potenza-title-tertiary">Revitalize Your Skin at Every Stage of Life</div>
                        <div className="desc">
                            <p>Your skin deserves care that evolves with you. Potenza RF Microneedling is a versatile treatment designed to stimulate collagen and refresh your skin.</p>
                        </div>
                        
                        <div className="points">
                                    <ul className="potenza-points">
                                        <li>Customizable for all skin types.</li>
                                        <li>Treats areas on the face and body.</li>
                                        <li>Minimal downtime.</li>
                                        <li>Visible results after 1 treatment session.</li>
                                    </ul>
                        </div>
                        <div className="column is-6 potenza-title-tertiary">Comprehensive Care for All Ages</div>
                        <div className="desc">
                            <p>Stimulating collagen production to target:</p>
                        </div>
                        <div className="points multi">
                                    <ul className="potenza-points">
                                        <li>acne</li>
                                        <li>pores</li>
                                        <li>skin tightening</li>
                                        <li>wrinkles and fine lines</li>
                                    </ul>
                                    <ul className="potenza-points">
                                        <li>skin laxity</li>
                                        <li>stretch marks</li>
                                        <li>post-op scarring</li>
                                    </ul>
                        </div>

                            <a className="contact-us-btn" href="#contact">
                                <div className="btn-container">
                                    <div className="btn-content">BOOK APPOINTMENT</div>
                                </div>
                            </a>
                    </div>
                     
                </div>
            </div>

       </section>


       <section id="profile">
              <div className="container">
                  <StaticImage alt="Dr. Antoaneta Mueller, M.D., FACOG, Premier Board Certified Ob-Gyn - South Bay CA" className="profile-img" quality="100" src="../images/home/about-dr-image@2x.png" />

                  <p className="profile-name">Dr. Antoaneta Mueller, M.D. <span className="cert-title">FACOG</span></p>
                  <p className="profile-intro">
                    Opening up my own practice has always been a dream of mine <br/> and I look forward to incorporating all of my past experiences into<br/> this new chapter of my life.
                  </p>

                  <div className="columns profile-experience">
                      <div className="column is-6">
                            <div className="profile-exp-title"><span className="exp-label">EXPERIENCE</span><img alt="Beyond Symbol" className="beyond-symbol" src={beyondSymbol} /></div>
                            <div className="profile-info-grp">
                                <p className="primary">Medical Director at Complete Women Care</p>
                                <p className="secondary">Long Beach, CA</p>
                                <p className="tertiary">2007-2021</p>
                            </div>
                            <div className="profile-info-grp">
                                <p className="primary">Medical Director of Gyn Emergent Care Center</p>
                                <p className="secondary">Long Beach, CA</p>
                                <p className="secondary">Providing 24/7 Gyn Emergent Care to all women</p>
                                <p className="tertiary">2013-2021</p>
                            </div>
                      </div>
                      <div className="column is-6">
                            <div className="profile-exp-title"><span className="exp-label">EDUCATION AND TRAINING</span><img alt="Beyond Symbol" className="beyond-symbol symbol-right" src={beyondSymbol} /></div>
                            <div className="profile-info-grp">
                                <p className="primary">Residency</p>
                                <p className="secondary">Jackson Memorial Hospital</p>
                                <p className="secondary">University of Miami Miller School of Medicine</p>
                                <p className="tertiary">2003-2007</p>
                            </div>
                            <div className="profile-info-grp">
                                <p className="primary">Medical School</p>
                                <p className="secondary">University of Miami Miller School of Medicine</p>
                                <p className="tertiary">1999-2003</p>
                            </div>
                      </div>
                  </div>

                  <Link className="learn-more-btn" aria-label="About Dr. Mueller" to="/about">
                      <div className="btn-container">
                        <div className="btn-content">MORE ABOUT Dr. Mueller</div>
                      </div>
                  </Link>
              </div>
       </section>


       <section id="office-space">
                <div className="container">
                    <div className="columns is-multiline">
                            <div className="column is-12">
                                <p className="office-space-title">Our Office</p>
                            </div>
                            <div className="column is-6">
                                    <StaticImage alt="Beyond Women's Health Office" className="office-img cbn" quality="100" src="../images/home/office-image-1.png" />
                            </div>
                            <div className="column is-6">
                                <div className="off-rows">
                                    <StaticImage alt="Beyond Women's Health Office" className="office-img lobby" quality="100" src="../images/home/office-image-2.png" />
                                    <StaticImage alt="Beyond Women's Health Office" className="office-img bldng" quality="100" src="../images/home/office-image-3.png" />
                                </div>
                            </div>
                        </div>
                </div>
       </section>

       <section id="our-team">
                <div className="container">
                    <div className="columns is-multiline">
                            <div className="column is-12">
                                <p className="our-team-title">Meet Our Team</p>
                            </div>
                            <div className="column is-12">
                                    <StaticImage alt="Beyond Women's Health Team" className="team-img" quality="100" src="../images/home/beyond-team-photo.png" />
                            </div>
                        </div>
                </div>
       </section>


       <section id="why-choose-us">
          <div className="container">

              <p className="why-us-title">Why Choose Beyond Women’s Health</p>
              <p className="why-us-title-secondary">Our 3-point approach to patient care</p>

              <div className="columns point-row">
                  <div className="column is-5-tablet is-6-desktop">
                      <StaticImage alt="Daughter Embracing Her Mother" className="point-img" quality="100" src="../images/home/feature-image-1@2x.png" />
                  </div>
                  <div className="column is-7-tablet is-6-desktop">
                      <div className="why-us-grp">
                            <div className="point-number">01</div>
                            <div className="point-title">INTEGRATIVE</div>
                            <div className="point-title-secondary">From Adolescence Into Menopause</div>
                            <p className="point-desc">Women’s health is a continuum from adolescence into menopause and at each stage your needs and expectations change. My approach is patient oriented and integrative.</p>
                      </div>
                  </div>
              </div>

              <div className="columns point-row second">
                  <div className="column is-5-tablet is-6-desktop">
                      <StaticImage alt="Female Gynecologist Smiling At Her Patient" className="point-img" quality="100" src="../images/home/feature-image-2@2x.png" />
                  </div>
                  <div className="column is-7-tablet is-6-desktop">
                      <div className="why-us-grp">
                            <div className="point-number">02</div>
                            <div className="point-title">COMPASSIONATE</div>
                            <div className="point-title-secondary">I Relate To My Patients Tremendously</div>
                            <p className="point-desc">As a working mother of two teenagers <br/> I relate to my patients tremendously.<br/> I cherish the relationships I have built thus far and look forward to forging new ones in the years to come.</p>
                      </div>
                  </div>

                  </div>
              
              <div className="columns point-row">

                  <div className="column is-5-tablet is-6-desktop">
                      <StaticImage alt="Cheerful Young Women Sitting At The Cafe" className="point-img" quality="100" src="../images/home/feature-image-3@2x.png" />
                  </div>
                  <div className="column is-7-tablet is-6-desktop">
                      <div className="why-us-grp">
                            <div className="point-number">03</div>
                            <div className="point-title">PATIENT CENTERED APPROACH</div>
                            <div className="point-title-secondary">I Listen To You</div>
                            <p className="point-desc">My past experiences have allowed me to understand the importance of patient feedback. This allows for a more compassionate and patient centered approach to health care.</p>
                      </div>
                  </div>
              </div>
          </div>
       </section>


       <section id="ready-to-schedule">
            <div className="container">
                <p className="schedule-title">Ready To Schedule?</p>
                <p className="schedule-desc">Let’s reach your health goals together. <br/> We’re here for you.</p>
                <div className="schedule-now-btn">
                      <div className="btn-container">
                        <div className="btn-content" onClick={contactScrollTo}>BOOK APPOINTMENT</div>
                      </div>
                </div>
            </div>
       </section>

       <section id="contact" ref={contactRef}>
          <div className="container">
              <div className="columns">
                  <div className="column is-6 contact-info-grp">
                      <div className="tile is-ancestor is-vertical">
                          <div className="tile contact-title">Our Offices</div>
                          <div className="tile contact-title-name">BEYOND WOMEN’S HEALTH</div>
                          <div className="tile contact-desc"><img alt="Location" className="contact-image" quality="100" src={locationIcon} />3655 Lomita Blvd Suite 321<br/> Torrance CA, 90505</div>
                          <a href="tel:310-325-1198" className="tile contact-number"><img alt="Call" className="contact-image" quality="100" src={phoneIcon} />(310) 325-1198</a>
                      </div>

                  </div>
                  <div className="column is-6 contact-map">
                      <StaticImage alt="Location Map for Beyond Women's Health" className="contact-map-image" quality="100" src="../images/common/location-map.png" />
                  </div>
              </div>
          </div>
       </section>
    </Layout>
  )
}

export default IndexPage
