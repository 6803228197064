import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/core/Layout"
import Navigation from "../components/core/Navigation"

import locationIcon from "../images/common/location-icon.svg"
import phoneIcon from "../images/common/phone-icon.svg"
import symbolGrey from "../images/common/symbol-beyond-grey.svg"

const ServicesPage = () => {
    return (
        <Layout siteTitle="Services - Beyond Women's Health">
            <section id="services-hero">
                <div className="container"><Navigation /></div>
                <div className="container">
                    <StaticImage alt="Female Gynecologist Smiling At Her Patient" className="serv-hero-img" imgStyle={{ objectFit: 'cover' }} src="../images/services/services-hero-image@2x.png"></StaticImage>
                    <div className="serv-title-prim" >Services We Offer</div>
                    <div className="serv-title-secnd-wrapper" >
                        <div className="serv-title-secnd">Services below are covered by most major insurances</div>
                        <img alt="Beyond Symbol Grey" className="serv-title-secnd-image" quality="100" src={symbolGrey} />
                    </div>
                </div>
                <div className="hero-bg"></div>
            </section>


            <section id="services-desc-gyn" className="services-desc">
                <div className="container">
                    <div className="service-row">
                        <div className="serv-list-grp">
                            <div><div className="serv-highlight-cont"><span className="serv-highlight">GYNECOLOGY CARE</span><span className="highlight-border"></span></div></div>
                            <ul className="serv-points">
                                <li>Well women exams
                                    <ul className="serv-points secondary-points">
                                        <li>Pap smears</li>
                                        <li>Breast exams</li>
                                        <li>Pelvic exams</li>
                                        <li>Mammograms</li>
                                        <li>Bone density</li>
                                        <li>STD testing</li>
                                        <li>Contraception</li>
                                    </ul>
                                </li>
                                <li>Management of abnormal pap smears</li>
                                <li>Endometriosis</li>
                                <li>Vulvovaginitis</li>
                            </ul>
                        </div>
                        <div className="serv-list-grp secondary-list"><ul className="serv-points">
                            <li>Urinary incontinence</li>
                            <li>Infertility evaluation</li>
                            <li>Irregular periods</li>
                            <li>Polycystic ovarian syndrome</li>
                            <li>Uterine fibroids</li>
                            <li>Uterine polyps</li>
                            <li>Cervical polyps</li>
                            <li>Heavy periods</li>
                            <li>Painful periods</li>
                            <li>Pelvic pain</li>
                            <li>Pelvic infection</li>
                            <li>Endometrial hyperplasia</li>
                        </ul>
                        </div>
                        <StaticImage alt="Happy Woman At Gynecology Exam" className="serv-picture" imgStyle={{ objectFit: 'contain' }} src="../images/home/service-image-gynecology@2x.png"></StaticImage>
                    </div>
                </div>
            </section>


            <section id="services-desc-surgery" className="services-desc">
                <div className="container">
                    <div className="service-row">
                        <StaticImage alt="Gynecologist With Female Patient In The Office" className="serv-picture" imgStyle={{ objectFit: 'contain' }} src="../images/home/service-image-gyn-surgery@2x.png"></StaticImage>
                        <div className="serv-list-grp first-list">
                            <div className="serv-highlight-cont"><span className="serv-highlight">GYNECOLOGIC PROCEDURES</span><span className="highlight-border"></span></div>
                            <div className="serv-point-row-grp">
                                <ul className="serv-points">
                                    <li>Pelvic/abdominal ultrasound</li>
                                    <li>Vulvar biopsy</li>
                                    <li>IUD insertion and removal</li>
                                    <li>Endometrial biopsy</li>
                                </ul>

                                <ul className="serv-points">
                                    <li>Colposcopy</li>
                                    <li className="point-multiline">Bartholin cyst and abscess drainage</li>
                                    <li>LEEP</li>
                                    <li>Cervical conization</li>
                                </ul>
                            </div>
                        </div>

                        <div className="serv-list-grp second-list">
                            <div className="serv-highlight-cont"><span className="serv-highlight">SURGICAL CONSULTS</span><span className="highlight-border"></span></div>
                            <div className="serv-point-row-grp">
                                <ul className="serv-points">
                                    <li>Hysterectomy
                                        <ul className="serv-points secondary-points">
                                            <li>Laparoscopic</li>
                                            <li>Vaginal</li>
                                            <li>Open/abdominal</li>
                                        </ul>
                                    </li>
                                    <li>Myomectomy</li>
                                    <li>Endometriosis</li>
                                    <li>Urinary leakage</li>
                                    <li>Bladder prolapse</li>
                                    <li>Uterine prolapse</li>
                                </ul>

                                <ul className="serv-points">
                                    <li>Hysteroscopy
                                        <ul className="serv-points secondary-points">
                                            <li>Endometrial ablation</li>
                                            <li>Dilation and curettage</li>
                                            <li>Myomectomy</li>
                                            <li>Polypectomy</li>
                                        </ul>
                                    </li>
                                    <li>Laparoscopy
                                        <ul className="serv-points secondary-points">
                                            <li>Ovarian cystectomy</li>
                                            <li>Oophorectomy</li>
                                            <li>Salpingectomy</li>
                                            <li>Endometriosis</li>
                                            <li>Ectopic pregnancy</li>
                                            <li>Tubal ligation</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section id="services-desc-meonopause" className="services-desc">
                <div className="container">
                    <div className="service-row">
                        <div className="serv-list-grp first-list">
                            <div className="serv-title-row"><div className="serv-highlight-cont"><span className="serv-highlight">MENOPAUSE</span><span className="highlight-border"></span></div>
                                <div className="new-label">New</div>
                            </div>
                            <div className="serv-title-secondary">
                                Empowered Wellness for Every Stage of Menopause
                            </div>
                            <div className="service-desc dsc">
                                <p>Experience my specialized practice, dedicated to supporting peri and menopausal women. Through an exclusive Membership Service, together we will reach new levels of wellbeing tailored to your unique needs.</p>
                            </div>
                            <div className="service-desc await">
                                <p>What Awaits You</p>
                            </div>
                            <div className="points">
                                    <ul className="menopause-points">
                                        <li>Personalized care designed to evolve with you</li>
                                        <li>Access to exclusive benefits that prioritize your health journey</li>
                                        <li>A supportive platform for your ongoing wellness</li>
                                    </ul>
                        </div>
                            <a className="learn-more-btn" href="https://beyondmymenopause.com/">
                                <div className="btn-container">
                                    <div className="btn-content">READY To GO BEYOND?</div>
                                </div>
                            </a>
                        </div>
                        <StaticImage alt="Young Pregnant Female Holding Tiny Socks Over Her Belly" className="serv-picture" imgStyle={{ objectFit: 'contain' }} src="../images/home/service-image-menopause@2x.png"></StaticImage>
                    </div>
                </div>
            </section>

            <section id="ready-to-schedule">
                <div className="container">
                    <p className="schedule-title">Ready To Schedule?</p>
                    <p className="schedule-desc">Let’s reach your health goals together. <br /> We’re here for you.</p>
                    <a className="schedule-now-btn" href="#contact">
                        <div className="btn-container">
                            <div className="btn-content">BOOK APPOINTMENT</div>
                        </div>
                    </a>
                </div>
            </section>

            <section id="contact">
                <div className="container">
                    <div className="columns">
                        <div className="column is-6 contact-info-grp">
                            <div className="tile is-ancestor is-vertical">
                                <div className="tile contact-title">Our Offices</div>
                                <div className="tile contact-title-name">BEYOND WOMEN’S HEALTH</div>
                                <div className="tile contact-desc"><img alt="Location" className="contact-image" quality="100" src={locationIcon} />3655 Lomita Blvd Suite 321<br /> Torrance CA, 90505</div>
                                <a href="tel:310-325-1198" className="tile contact-number"><img alt="Call" className="contact-image" quality="100" src={phoneIcon} />(310) 325-1198</a>
                            </div>

                        </div>
                        <div className="column is-6 contact-map">
                            <StaticImage alt="Location Map for Beyond Women's Health" className="contact-map-image" quality="100" src="../images/common/location-map.png" />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default ServicesPage
